import lazySizes from 'lazysizes'; // eslint-disable-line
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/optimumx/ls.optimumx';

import './js/search.es6';
import './js/sort.es6';
import './js/analytics/search-analytics.es6';
import './js/analytics/user-analytics.es6';
import './js/recommender.es6';
import './js/play-button.es6';
import './js/rating-button.es6';
import './js/banner.es6';

import './js/favorite-button.es6';
import './js/message-box.es6';
import './js/accordion.es6';
import headerMenu from './js/header.es6';
import heroSplit from './js/hero.es6';

import './css/main.scss';

headerMenu.headerMenu();

// Adding a 'ios-device' class to the <body> element, if the browser is running on Apple iOS.
const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
if (iOS) {
	document.body.className += ' ios-device';
}


// Configure lazySizes
const lazySizesConfig = window.lazySizesConfig || {};
// lazySizesConfig.preloadAfterLoad = true; // speed up initial loading of in view images
lazySizesConfig.expand = 1000;
// lazySizesConfig.loadMode = 1; //page is optimized for fast onload event
lazySizes.init();

if (document.readyState !== 'loading') {
	initJavaScript();
} else {
	document.addEventListener('DOMContentLoaded', initJavaScript);
}

function initJavaScript() {
	document.querySelectorAll('.m-banner__text-container').forEach(heroSplit);
}
