import { post } from '../common/index.es6';
import { getCookie, COOKIES } from '../common/cookie.es6';

const { tineAPI } = window;

const SEARCH_RESULTS_CLASS = '.o-search-results';
const SEARCH_ID_STORAGE_KEY = 'searchId';
const LAST_SEARCH_TERM_KEY = 'lastSearchTerm';
export const STORAGE_DATA_KEY = 'searchAnalyticsData';
export const STORAGE_TIMESTAMP_KEY = 'searchAnalyticsTimestamp';
export const STORAGE_TARGET_PATHNAME_KEY = 'searchAnalyticsTargetPathname';

export function clearStoredSearchId(form, forceClear = false) {
	const currentSearchTerm = form.querySelector('input[type="text"]').value;
	if ((currentSearchTerm !== sessionStorage.getItem(LAST_SEARCH_TERM_KEY)) || forceClear) {
		sessionStorage.removeItem(SEARCH_ID_STORAGE_KEY);
		sessionStorage.removeItem(LAST_SEARCH_TERM_KEY);
	}
}

function getSearchResultsEl() {
	return document.querySelector(SEARCH_RESULTS_CLASS);
}

function getCards(searchResultEl) {
	return [...searchResultEl.querySelectorAll('ol.o-collection--search > li')];
}

function logSearchView() {
	const searchResultsEl = getSearchResultsEl();
	if (searchResultsEl) {
		const itemEls = getCards(searchResultsEl);
		logSearch('impression', itemEls);
	}
}

function getSearchTerm() {
	let { searchTerm } = getSearchResultsEl().dataset;
	searchTerm = decodeURIComponent(searchTerm);
	return searchTerm || '';
}

function getItemAnchorElement(itemEl) {
	return itemEl.querySelector('a') || {};
}

function createData(event, itemEls) {
	function createItem(itemEl, pageNumber, pageListSize) {
		if (!itemEl) {
			return {
				id: null,
				title: null,
				recipeId: null,
				positionOnPage: null,
				impressionId: null,
				url: null,
				workTime: null,
				absolutePosition: null,
				contentType: null,
				rating: null,
				isFavorite: null
			};
		}
		const {
			id,
			title,
			recipeId = null,
			index,
			impressionId = null,
			workTime = null,
			contentType = null,
			rating = null,
			isFavorite = 'false'
		} = itemEl.dataset;
		const positionOnPage = parseInt(index || 0, 10);
		const parsedRating = rating ? parseFloat(rating || 0) : null;
		const parsedIsFavorite = JSON.parse(isFavorite);
		const url = getItemAnchorElement(itemEl).href;
		const absolutePosition = (pageNumber * pageListSize) + positionOnPage;
		return {
			id,
			title,
			recipeId,
			positionOnPage,
			impressionId,
			url,
			workTime,
			absolutePosition,
			contentType,
			rating: parsedRating,
			isFavorite: parsedIsFavorite
		};
	}

	const searchResultsEl = getSearchResultsEl();
	const {
		pageNumber = 1,
		searchTerm = '',
		totalHits,
		pageListSize,
		alternativeQuery = null
	} = searchResultsEl.dataset;
	const decodedSearchTerm = decodeURIComponent(searchTerm);
	const parsedPageNumber = parseInt(pageNumber, 10);
	const zeroIndexedPageNumber = (Number.isNaN(parsedPageNumber) ? 1 : parsedPageNumber) - 1;
	const pageListSizeNumber = parseInt(pageListSize, 10);
	const parsedPageListSize = Number.isNaN(pageListSizeNumber) ? 0 : pageListSizeNumber;
	const items = itemEls.length
		? itemEls.map(itemEl => createItem(itemEl, zeroIndexedPageNumber, parsedPageListSize))
		: createItem(null, zeroIndexedPageNumber, parsedPageListSize);
	const userAgent = (navigator && navigator.userAgent) || '';
	const windowWidth = (window && window.innerWidth) || 0;
	const windowHeight = (window && window.innerHeight) || 0;
	const searchId = sessionStorage.getItem(SEARCH_ID_STORAGE_KEY);
	const filters = createFilterData();
	return {
		event: itemEls.length ? event : 'zerohits',
		searchTerm: decodedSearchTerm,
		totalHits: parseInt(totalHits || 0, 10),
		pageNumber: zeroIndexedPageNumber,
		maxItemsPerPage: parsedPageListSize,
		userAgent,
		userId: getCookie(COOKIES.TK_USER_ID) || null,
		windowWidth,
		windowHeight,
		searchId,
		correctedSearchTerm: alternativeQuery,
		searchTermGaveZeroHits: !itemEls.length || !!alternativeQuery,
		items: JSON.stringify(items),
		filters
	};
}

function createFilterData() {
	const filterContainer = document.querySelector('.o-kitchen-header--filter'); // Both the desktop and the mobile filter have their boxes prechecked on load, so either one can be used here.
	const categoryContainer = [...filterContainer.querySelectorAll('.o-kitchen-header--filter__element')];
	let filters = '';

	categoryContainer
		.forEach((filterCategoryContainer) => {
			const categoryTitle = filterCategoryContainer.querySelector('.a-accordion__title-span').innerHTML;
			const checkboxContainers = [...filterCategoryContainer.querySelectorAll('.a-accordion__panel')];
			checkboxContainers.forEach((checkboxContainer) => {
				const checkboxTitle = checkboxContainer.querySelector('.o-kitchen-header--filter__title').innerHTML;
				const checkbox = checkboxContainer.querySelector('input[type="checkbox"]');
				const checkedValue = checkbox.checked ? 1 : 0;
				const prefix = filters.length ? ';' : '';
				filters += `${prefix}${categoryTitle}_${checkboxTitle}_${checkedValue}`;
			});
		});
	return filters;
}

function logSearch(event, itemEls) {
	if (event && itemEls) {
		const body = createData(event, itemEls);
		postLogRequest(body, itemEls);
	}
}

export function postLogRequest(body, itemEls = []) {
	const url = tineAPI.searchAnalyticsUrl;
	post(url, JSON.stringify(body))
		.then(response => response.json())
		.then((data) => {
			updateImpressions(data, itemEls);
			updateSearchId(data);
		})
		.catch(() => {});
}

function updateSearchId({ searchId }) {
	if (searchId) {
		sessionStorage.setItem(SEARCH_ID_STORAGE_KEY, searchId);
		sessionStorage.setItem(LAST_SEARCH_TERM_KEY, getSearchTerm());
	} else {
		clearStoredSearchId(undefined, true);
	}
}

function updateImpressions({ items }, itemEls = []) {
	itemEls.forEach((itemEl, index) => {
		const impressionId = items[index] && items[index].impressionId;
		if (impressionId) {
			itemEl.dataset.impressionId = impressionId;
		}
	});
}

export function clearStoredValues() {
	localStorage.removeItem(STORAGE_DATA_KEY);
	localStorage.removeItem(STORAGE_TIMESTAMP_KEY);
	localStorage.removeItem(STORAGE_TARGET_PATHNAME_KEY);
}

function setupClickListeners() {
	function storeValues(element) {
		const {
			id
		} = element.dataset;
		const searchResultsEl = getSearchResultsEl();
		const itemEl = searchResultsEl.querySelector(`[data-id="${id}"]`);
		const data = createData('click', [itemEl]);
		localStorage.setItem(STORAGE_DATA_KEY, JSON.stringify(data));
		localStorage.setItem(STORAGE_TIMESTAMP_KEY, JSON.stringify(Date.now()));
		const targetPathname = getItemAnchorElement(itemEl).pathname;
		localStorage.setItem(STORAGE_TARGET_PATHNAME_KEY, targetPathname);
	}

	let timer;
	const touchDuration = 500;
	function clickdown(itemEl) {
		timer = setTimeout(() => storeValues(itemEl), touchDuration);
	}

	function clickdownClear() {
		if (timer) {
			clearTimeout(timer);
		}
	}

	const searchResultsEl = getSearchResultsEl();
	if (searchResultsEl) {
		clearStoredValues();
		const itemEls = getCards(searchResultsEl);
		if (itemEls) {
			itemEls.forEach((itemEl) => {
				itemEl.addEventListener('click', () => storeValues(itemEl));
				itemEl.addEventListener('contextmenu', () => storeValues(itemEl));
				// Not all mobile browsers (specifically on iOS) fire contextmenu event on long press. So touchstart/touchend is needed.
				itemEl.addEventListener('touchstart', () => clickdown(itemEl));
				itemEl.addEventListener('touchend', clickdownClear);
				itemEl.addEventListener('touchmove', clickdownClear);
				// Middle mouse button doesn't trigger a normal 'click' event, hence this workaround.
				itemEl.addEventListener('mousedown', () => clickdown(itemEl));
				itemEl.addEventListener('mouseup', clickdownClear);
				itemEl.addEventListener('mousemove', clickdownClear);
			});
		}
	}
}

function logSearchClick() {
	function timeSinceClick(timestamp) {
		return Date.now() - timestamp;
	}

	const searchClickData = JSON.parse(localStorage.getItem(STORAGE_DATA_KEY));
	const timestamp = JSON.parse(localStorage.getItem(STORAGE_TIMESTAMP_KEY));
	const urlsMatch = localStorage.getItem(STORAGE_TARGET_PATHNAME_KEY) === window.location.pathname;
	clearStoredValues();
	/**
	 * Would check referrer as well,
	 * but some (mobile) browsers (at least Firefox Android) set referrer to undefined when opening in new tab.
	 */
	const enteredFromSearchPage = urlsMatch && searchClickData && timeSinceClick(timestamp) < 10000;
	if (enteredFromSearchPage) {
		const clickOpenedInNewTab = history ? history.length === 1 : null; // eslint-disable-line no-restricted-globals
		postLogRequest({
			...searchClickData,
			clickOpenedInNewTab
		});
	}
}
(() => {
	setupClickListeners();
	logSearchView();
	logSearchClick();
})();
