import { post } from '../common/index.es6';
import { getCookie, COOKIES } from '../common/cookie.es6';

const { tineAPI } = window;

let currentTimerId;
let currentTimerLength;
let currentTimerStarted;
let currentTimerAbsoluteSeconds;
let currentTimerEvent;
let timerRemainingMillis;

function createData(event, secondsElapsed = null, rating = null) {
	const userAgent = (navigator && navigator.userAgent) || '';
	const windowWidth = (window && window.innerWidth) || 0;
	const windowHeight = (window && window.innerHeight) || 0;
	const urlWithouyQueryString = window.location.href.split('?')[0];
	return {
		url: urlWithouyQueryString,
		recipeId: getRecipeId(),
		xpNodeId: getDatasetValue('body', 'xpNodeId'),
		userId: getCookie(COOKIES.TK_USER_ID) || null,
		event,
		userAgent,
		windowWidth,
		windowHeight,
		secondsElapsed,
		userRating: rating
	};
}

function getRecipeId() {
	return getDatasetValue('#recipe-part', 'uuid');
}

function getDatasetValue(selector, key) {
	const element = document.querySelector(selector);
	if (!element) {
		return null;
	}
	return element.dataset[key];
}

function logEvent(event, secondsElapsed = null, rating = null) {
	const body = createData(event, secondsElapsed, rating);
	postLogRequest(body);
}

export function postLogRequest(body) {
	const url = tineAPI.userAnalyticsUrl;
	post(url, JSON.stringify(body))
		.catch(() => {});
}

function logPageView() {
	logEvent('pageView', 0);
}

export function logRating(rating) {
	logEvent('rating', null, parseInt(rating, 10));
}

const CONVERSION_TIMER_SECONDS = 180;
const timers = [30, 60, CONVERSION_TIMER_SECONDS, 300, 600];

function getNextTimer() {
	return timers.shift();
}

function setupTimerEvent(event, timeoutInSeconds, secondsElapsed) {
	const timerInMillis = timeoutInSeconds * 1000;
	currentTimerLength = timerInMillis;
	currentTimerStarted = Date.now();
	currentTimerAbsoluteSeconds = secondsElapsed;
	currentTimerEvent = event;
	currentTimerId = setTimeout(logTimerEvent, timerInMillis, event, secondsElapsed);
}

function logTimerEvent(event, secondsElapsed) {
	logEvent(event, secondsElapsed);
	currentTimerLength = null;
	currentTimerStarted = null;
	currentTimerAbsoluteSeconds = null;
	currentTimerEvent = null;
	currentTimerId = null;
	const nextTimer = getNextTimer();
	if (nextTimer) {
		const nextEvent = nextTimer === CONVERSION_TIMER_SECONDS ? 'conversion' : 'timer';
		const timeout = nextTimer - secondsElapsed;
		setupTimerEvent(nextEvent, timeout, nextTimer);
	}
}

function setupVisibilityChangeHandler() {
	document.addEventListener('visibilitychange', handleVisibility, false);
}

function handleVisibility() {
	if (document.hidden) {
		if (currentTimerId) {
			clearTimeout(currentTimerId);
			currentTimerId = null;
			const timerRanFor = Date.now() - currentTimerStarted;
			timerRemainingMillis = currentTimerLength - timerRanFor;
		}
	} else if (!currentTimerId) {
		const pausedTimerExists = currentTimerEvent && currentTimerAbsoluteSeconds;
		if (pausedTimerExists) {
			const timerRemainingSeconds = timerRemainingMillis / 1000;
			setupTimerEvent(currentTimerEvent, timerRemainingSeconds, currentTimerAbsoluteSeconds);
			timerRemainingMillis = null;
		}
	}
}

/**
 * Some devices/browsers seem to be struggling with multiple simultaneous timers. To avoid these issues, we make each
 * timeout spawn the next, instead of spawning them all at once.
 */
function setupEventTimers() {
	const firstTimer = getNextTimer();
	setupTimerEvent('timer', firstTimer, firstTimer);
	handleVisibility();
	setupVisibilityChangeHandler();
}

(() => {
	logPageView();
	const isRecipePage = !!getRecipeId();
	if (isRecipePage) {
		setupEventTimers();
	}
})();
