export default function heroSplit(el) {
	if (el) {
		const coverEL = document.querySelector('.m-banner__image');
		coverEL.setAttribute('id', 'tk-cover');

		const h1El = el.querySelector('.m-banner__title');
		const heroText = h1El.textContent.trim();
		const textArray = heroText.split(' ');

		const htmlContentReplace = textArray.map(word => `<span class="tk-hero__title">${word}</span>`).join(' ');

		h1El.textContent = '';
		h1El.innerHTML = htmlContentReplace;
	}
}
